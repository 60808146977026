import { Field } from 'react-final-form'
import { Input } from './Input'

type Props = {
    label: string
    className?: string
    placeholder?: string
    name: string
}

export const AmountInputField = ({label, name, className, placeholder}: Props) => {
    return (
        <Field 
            name={name} 
            type="number" 
            render={({input, meta}) => {
                return (
                    <Input
                        label={label}
                        className={className} 
                        placeholder={placeholder}
                        min={0}
                        {...input}
                        {...meta}
                    />
                )
            }}
        />
    )
}