import cx from 'classnames'
import { MouseEventHandler, ReactNode } from 'react'

type Props = {
    children: ReactNode
    className?: string
    type?: 'button' | 'submit' | 'reset'
    onClick?: MouseEventHandler<HTMLButtonElement>
}

export const Button = ({children, className, type, onClick}: Props) => {
    return (
        <button
            type={type} 
            onClick={onClick}
            className={cx('text-white bg-amber-500 hover:opacity-90 active:opacity-80 font-medium font-semibold rounded-lg text-base px-5 py-2.5 focus:outline-none ', className)}
        >
            {children}
        </button>
    )
}