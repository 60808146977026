import { Field } from 'react-final-form'
import { Option, Select } from './Select'

type Props = {
    label: string
    className?: string
    placeholder?: string
    name: string
    options: Option[]
    defaultValue?: string
}

export const SelectField = ({label, name, className, placeholder, options, defaultValue}: Props) => {
    return (
        <Field name={name} type="select" defaultValue={defaultValue} render={({input, meta}) => {
            return (
                <Select
                    label={label}
                    className={className} 
                    placeholder={placeholder}
                    options={options}
                    {...input}
                    {...meta}
                />
            )
        }}/>
    )
}