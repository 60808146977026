import { Form } from "react-final-form"
import { AmountInputField } from "../components/inputs/AmountInputField"
import { SelectField } from "../components/inputs/SelectField"
import { Button } from "../components/Button"
import { useEffect, useState } from "react"
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { Alert } from "../components/Alert"

const CONFIG_OPTIONS = [
    { label: 'exp1.com', value: 'exp1' },
    { label: '911groundzero.com', value: '911groundzero' },
    { label: 'thewallstreetexperience.com', value: 'thewallstreetexperience' },
]

const CURRENCY_OPTIONS = [
    { label: 'USD', value: 'USD' },
    { label: 'EUR', value: 'EUR' },
]

export const GenerateLink = () => {
    useEffect(() => {
        const link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
        link.href = 'https://www.exp1.com/wp-content/uploads/2020/08/favicon.png'
        document.title = 'Generate payment link'
    }, [])

    const [link, setLink] = useState('')

    const configOptions = window.location.host === 'localhost:3000' 
        ? [{ label: window.location.host, value: 'localhost'}, ...CONFIG_OPTIONS]
        : CONFIG_OPTIONS

    const onSubmit = (values: any) => {
        if (!values.amount) {
            return {
                amount: 'Incorrect value'
            }
        }

        const code = btoa(JSON.stringify({
            configCode: values.configCode,
            currency: values.currency,
            amount: values.amount * 100,
        }))

        const configOption = configOptions.find((option) => option.value === values.configCode)

        if (!configOption) {
            throw new Error(`Can't find config option by value '${values.configCode}'`)
        }

        const serviceUrl = values.configCode === 'localhost' ? 'http://localhost:3000' : `https://pay.${configOption.label}`

        setLink(`${serviceUrl}/${code}`)
    }

    const [copied, setCopied] = useState(false)

    useEffect(() => {
        if (!copied) {
            return 
        }
        const id = setTimeout(() => setCopied(false), 3000)
        return () => clearTimeout(id)
    }, [copied])

    return (
        <>  
            <h6 className="text-2xl font-bold mx-auto">Generate payment link</h6>
            <Form onSubmit={onSubmit} render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <SelectField
                            label="Site"
                            name="configCode"
                            defaultValue="exp1"
                            options={configOptions}
                        />
                        <AmountInputField label="Amount" name="amount" />
                        <SelectField label="Currency" name="currency" defaultValue="USD" options={CURRENCY_OPTIONS} />
                        <Button>Generate link</Button>
                    </form>
                )
            }} />
            {link && (
                <>
                    <Alert className="text-green-800 rounded-lg bg-green-50 border border-green-500 mt-4 break-all">
                        {link}
                    </Alert>
                    <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
                        <Button className="bg-blue-400">{copied ? "Copied" : "Copy link"}</Button>
                    </CopyToClipboard>
                </>
            )}
        </>
    )
}