import cx from 'classnames'
import { Label } from '../Label'
import { ErrorMessage } from '../ErrorMessage'

export type Option = {
    label: string
    value: string
}

type Props = {
    label: string
    className?: string
    placeholder?: string
    name?: string
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    error?: string
    submitError?: string
    options: Option[]
}

export const Select = ({label, name, className, placeholder, value, onChange, error, submitError, options}: Props) => {
    const fieldError = submitError || error

    return (
        <div className={cx('flex flex-col gap-2', className)}>
            <div className="flex gap-1 items-baseline">
                <Label>{label}</Label>
                {fieldError && <ErrorMessage error={fieldError}/>}
            </div>
            <select 
                name={name}
                value={value}
                onChange={onChange}
                className={cx("bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5", {
                    'border-red-500': fieldError
                })} 
                placeholder={placeholder}
            >
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    )
                })}
            </select>
        </div>
    )
}