import { pickBy, size } from 'lodash'

const EMAIL_RE = /^.*@.*\..*$/

const validateEmail = (email: any) => {
    if (typeof email !== 'string' || !email.trim().match(EMAIL_RE)) {
        return 'Incorrect email'
    }

    return undefined
}

const validateAccepted = (accepted: any) => {
    if (accepted !== true) {
        return 'Terms not accepted'
    }

    return undefined
}

export const validate = (values: any) => {
    const checks = {
        email: validateEmail(values.email),
        accepted: validateAccepted(values.accepted),
    }

    const errors = pickBy(checks, Boolean)

    return size(errors) > 0 ? errors : undefined
}