import { Field } from 'react-final-form'
import { Input } from './Input'

type Props = {
    label: string
    className?: string
    placeholder?: string
    name: string
}

export const EmailInputField = ({label, name, className, placeholder}: Props) => {
    return (
        <Field name={name} type="email" render={({input, meta}) => {
            return (
                <Input
                    label={label}
                    className={className} 
                    placeholder={placeholder}
                    {...input}
                    {...meta}
                />
            )
        }}/>
    )
}