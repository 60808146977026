import cx from 'classnames'
import { ReactNode } from "react"

type Props = {
    children: ReactNode
    className?: string
}

export const Alert = ({className, children}: Props) => {
    return (
        <div role="alert" className={cx("p-4 mb-4 text-sm rounded-lg", className)}>{children}</div>
    )
}