import cx from 'classnames'
import { ReactNode } from 'react'

type Props = {
    htmlFor?: string
    children: ReactNode
    className?: string
}

export const Label = ({children, className, htmlFor}: Props) => {
    return (
        <label htmlFor={htmlFor} className={cx('text-sm', className)}>{children}</label>
    )
}