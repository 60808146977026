import { Field } from 'react-final-form'
import { Checkbox } from './Checkbox'
import { ReactNode } from 'react'

type Props = {
    id?: string
    label: ReactNode
    className?: string
    placeholder?: string
    name: string
}

export const CheckboxField = ({id, label, name, className}: Props) => {
    return (
        <Field name={name} type="checkbox" render={({input, meta}) => {
            return (
                <Checkbox
                    id={id}
                    label={label}
                    className={className} 
                    {...input}
                    {...meta}
                />
            )
        }}/>
    )
}