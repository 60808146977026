import { QueryClient, QueryClientProvider } from 'react-query'
import { Checkout } from './pages/Checkout'
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom'
import { ThankYou } from './pages/ThankYou'
import { GenerateLink } from './pages/GenerateLink'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex justify-center bg-[#f6f9fc] min-h-screen">
        <div className="sm:w-[750px] w-screen flex flex-col items-center bg-white sm:p-10 p-4 sm:h-min sm:shadow-lg sm:rounded gap-4 relative sm:mt-16">
          <div className="sm:w-[500px] w-full flex flex-col bg-white h-min gap-4">
            <Router>
              <Routes>
                <Route path="/thank-you" element={<ThankYou/>} />
                <Route path="/generate-link" element={<GenerateLink/>} />
                <Route path="/*" element={<Checkout/>} />
              </Routes>
            </Router>
          </div>
        </div>
      </div>
    </QueryClientProvider>
  )
}

export default App
