import { useLocation } from "react-router-dom"

export const ThankYou = () => {
    const {state} = useLocation()
    
    return (
        <div className="flex flex-col gap-4 items-center">
            <img src={state.logoUrl} alt="logo" className="max-h-[50px] min-h-[50px] mb-6 sm:mb-24" />
            <h2 className="text-xl">Thank you for choosing ExperienceFirst.</h2>
            <p>Your transation ID is <i>{state.transactionId}</i></p>
            <p>Please check your email for confirmation.</p>
            <p><a href={state.url} className="font-medium text-blue-600 hover:underline cursor-pointer">Go Back</a></p>
        </div>
    )
}