import cx from 'classnames'
import { get } from 'lodash'

type Props = {
    error: any
    className?: string
}

export const ErrorMessage = ({error, className}: Props) => {
    const message = typeof error === 'string' ? error : get(error, 'response.data.error', error.message)
    return (
        <span className={cx('text-[13px] text-red-500', className)}>{message}</span>
    )
}