import { Form } from 'react-final-form'
import { validate } from './validate'
import { useIntentQuery } from '../../hooks/useIntentQuery'
import { useEffect, useRef, useState } from 'react'
import { usePayMutation } from '../../hooks/usePayMutation'
import { CheckoutForm } from './CheckoutForm'
import { ErrorMessage } from '../../components/ErrorMessage'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../../components/Spinner'
import { useWidgetParams } from '../../hooks/useWidgetParams'
import { useSettingsQuery } from '../../hooks/useSettingsQuery'

type Props = {
    className?: string
}

export const Checkout = ({ className }: Props) => {
    const navigate = useNavigate()
    const params = useWidgetParams()
    const nexioIframe = useRef<HTMLIFrameElement>(null)
    const [iframeError, setIframeError] = useState<any>(null)
    const [iframeLoading, setIframeLoading] = useState(true)

    const intentQuery = useIntentQuery({
        configCode: params.configCode,
        serviceUrl: params.serviceUrl,
        currency: params.currency,
        amount: params.amount
    })

    const settingsQuery = useSettingsQuery({
        configCode: params.configCode,
        currency: params.currency,
    })

    const payMutation = usePayMutation()

    useEffect(() => {
        if (!settingsQuery.data) {
            return
        }

        const link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
        link.href = settingsQuery.data.faviconUrl

        document.title = settingsQuery.data.title

    }, [settingsQuery.data])


    const onSubmit = async (values: any) => {
        const errors = validate(values)

        if (errors) {
            return errors
        }

        if (!intentQuery.data || !settingsQuery.data || !nexioIframe || !nexioIframe.current || !nexioIframe.current.contentWindow) {
            throw new Error('Nexio iframe is not ready')
        }

        nexioIframe.current.contentWindow.postMessage('posted', settingsQuery.data.nexioUrl)
    }

    const loading = iframeLoading || intentQuery.isLoading || settingsQuery.isLoading || payMutation.isLoading
    const error = iframeError || intentQuery.error || payMutation.error as any

    return (
        <Form onSubmit={onSubmit} render={({ handleSubmit, values }) => {
            const onError = (error: any) => {
                setIframeLoading(false)
                setIframeError(error)
            }

            const onCardSaved = (token: string) => {
                payMutation.mutate({
                    cardToken: token,
                    email: values.email,
                    amount: params.amount,
                    currency: params.currency,
                    configCode: params.configCode
                }, {
                    onSuccess({ transactionId }) {
                        if (!settingsQuery.data) {
                            throw new Error('Settings is not loaded')
                        }

                        navigate('/thank-you', {
                            state: {
                                transactionId,
                                logoUrl: settingsQuery.data.logoUrl,
                                faviconUrl: settingsQuery.data.faviconUrl,
                                style: settingsQuery.data.style,
                                url: settingsQuery.data.url
                            }
                        })
                    },
                    onError() {
                        setIframeLoading(false)
                        intentQuery.refetch()
                    }
                })
            }

            return (
                <div className="flex flex-col gap-4 min-h-[590px]">
                    <CheckoutForm
                        intent={intentQuery.data}
                        settings={settingsQuery.data}
                        handleSubmit={handleSubmit}
                        className={className}
                        nexioIframe={nexioIframe}
                        onSubmit={() => setIframeLoading(true)}
                        onError={onError}
                        onLoaded={() => setIframeLoading(false)}
                        onCardSaved={onCardSaved}
                    />
                    {error && <ErrorMessage error={error} />}
        
                    {loading && <Spinner />}
                </div>
            )
        }} />

    )
}