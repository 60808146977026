import cx from 'classnames'
import { Label } from '../Label'
import { ErrorMessage } from '../ErrorMessage'

type Props = {
    label: string
    className?: string
    placeholder?: string
    name?: string
    value?: number
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    error?: string
    submitError?: string
    type?: string
    min?: number
    max?: number
}

export const Input = ({label, name, className, placeholder, value, onChange, error, submitError, type, min, max}: Props) => {
    const fieldError = submitError || error

    return (
        <div className={cx('flex flex-col gap-2', className)}>
            <div className="flex gap-1 items-baseline">
                <Label>{label}</Label>
                {fieldError && <ErrorMessage error={fieldError}/>}
            </div>
            <input 
                name={name}
                type={type}
                min={min}
                max={max}
                value={value}
                onChange={onChange}
                className={cx("bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5", {
                    'border-red-500': fieldError
                })} 
                placeholder={placeholder}
            />
        </div>
    )
}