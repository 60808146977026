import { EmailInputField } from '../../components/inputs/EmailInputField'
import { formatMoney } from 'common-utils'
import { Button } from '../../components/Button'
import { CSSProperties, useEffect, useState } from 'react'
import { Responses } from '../../commonTypes'
import { CheckboxField } from '../../components/inputs/CheckboxField'

type Props = {
  intent?: Responses.Intent
  settings?: Responses.Settings
  handleSubmit: () => void
  onSubmit: () => void
  onCardSaved: (token: string) => void
  onLoaded: () => void
  onError: (error: any) => void
  className?: string
  nexioIframe: React.MutableRefObject<HTMLIFrameElement | null>
}

export const CheckoutForm = ({intent, settings, handleSubmit, className, onSubmit, onCardSaved, onLoaded, onError, nexioIframe}: Props) => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!settings) {
      return
    }

    function messageProcessing(event: any) {
      if (!settings) {
        return
      }

      if (event.origin !== settings.nexioUrl) {
        return
      }

      switch (event.data.event) {
        case 'submit':
          onSubmit()
          return

        case 'cardSaved':
          window.removeEventListener('message', messageProcessing)
          onCardSaved(event.data.data.token.token)
          return

        case 'loaded':
          onLoaded()
          setReady(true)
          return

        case 'error':
          onError(event.data.data)
          return
      }
    }

    window.addEventListener('message', messageProcessing)

    return () => {
      window.removeEventListener('message', messageProcessing)
    }
  }, [settings, onSubmit, onLoaded, onCardSaved, onError])

  return (
    <form onSubmit={handleSubmit} >
      {settings && (
        <>
          <img src={settings.logoUrl} alt="logo" className="max-h-[50px] mb-6 mx-auto" />
          <div className="w-full flex flex-col gap-4" style={settings.style as CSSProperties}>

            {intent && (
              <>
                <iframe
                  ref={nexioIframe}
                  className="h-[333px]"
                  src={`${settings.nexioUrl}/pay/v3/saveCard?token=${intent.oneTimeUseToken}&shouldReturnHtml=true`}
                  title="Checkout"
                />
                {ready && (
                  <>
                    <EmailInputField name="email" label="Email:" />
                    <CheckboxField id="accepted" name="accepted" label={(
                      <span>
                        I've read and accept the <a href={settings.termsUrl} className="font-medium text-blue-600 hover:underline cursor-pointer">Terms and Conditions</a>
                      </span>
                    )} />
                    <Button
                      type="submit"
                      className='!text-[color:var(--cp-text-color)] !bg-[color:var(--cp-bg-color)]'
                    >
                      Pay {formatMoney(intent.amount, intent.currency)}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </form>
  )
}