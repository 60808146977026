import cx from 'classnames'
import { Label } from '../Label'
import { ErrorMessage } from '../ErrorMessage'
import { ReactNode } from 'react'

type Props = {
    id?: string
    label: ReactNode
    className?: string
    name?: string
    checked?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    error?: string
    submitError?: string

}

export const Checkbox = ({id, label, name, className, checked, onChange, error, submitError}: Props) => {
    const fieldError = submitError || error

    return (
        <div className={cx('flex gap-2 items-center', className)}>
            <input 
                id={id}
                name={name}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className={cx("w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded", {
                    'border-red-500': fieldError
                })} 
            />
            <div className="flex gap-1 items-baseline">
                <Label htmlFor={id}>{label}</Label>
                {fieldError && <ErrorMessage error={fieldError}/>}
            </div>
        </div>
    )
}