export const useWidgetParams = () => {
    const data = JSON.parse(atob(window.location.pathname.slice(1)))

    if (!data.configCode) {
        throw new Error(`Config code not passed`)
    }

    if (!data.currency) {
        throw new Error(`Currency not passed`)
    }

    if (typeof data.amount !== 'number') {
        throw new Error(`Amount not passed`)
    }

    return {
        serviceUrl: window.location.origin,
        ...data,
    }
}